<template>
  <HelloWorld />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
  html, body, #app{width:100% !important;height:100% !important;}
  body{ background: rgb(7,46,74);background: radial-gradient(circle, rgba(7,46,74,1) 0%, rgba(6,34,55,1) 100%); }
  body, body *{font-family: 'Fjalla One', sans-serif;}
</style>
