<template>
  <div class="container-fluid m-0 p-0">
    <svg xmlns:xlink="http://www.w3.org/1999/xlink" id="wave" style="transform:rotate(180deg); transition: 0.3s" viewBox="0 0 1440 460" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(255, 255, 255, 1)" offset="0%"/><stop stop-color="rgba(255, 255, 255, 1)" offset="100%"/></linearGradient></defs><path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)" d="M0,46L60,53.7C120,61,240,77,360,76.7C480,77,600,61,720,107.3C840,153,960,261,1080,299C1200,337,1320,307,1440,245.3C1560,184,1680,92,1800,92C1920,92,2040,184,2160,199.3C2280,215,2400,153,2520,145.7C2640,138,2760,184,2880,207C3000,230,3120,230,3240,199.3C3360,169,3480,107,3600,69C3720,31,3840,15,3960,15.3C4080,15,4200,31,4320,92C4440,153,4560,261,4680,283.7C4800,307,4920,245,5040,237.7C5160,230,5280,276,5400,253C5520,230,5640,138,5760,145.7C5880,153,6000,261,6120,253C6240,245,6360,123,6480,122.7C6600,123,6720,245,6840,291.3C6960,337,7080,307,7200,291.3C7320,276,7440,276,7560,245.3C7680,215,7800,153,7920,176.3C8040,199,8160,307,8280,337.3C8400,368,8520,322,8580,299L8640,276L8640,460L8580,460C8520,460,8400,460,8280,460C8160,460,8040,460,7920,460C7800,460,7680,460,7560,460C7440,460,7320,460,7200,460C7080,460,6960,460,6840,460C6720,460,6600,460,6480,460C6360,460,6240,460,6120,460C6000,460,5880,460,5760,460C5640,460,5520,460,5400,460C5280,460,5160,460,5040,460C4920,460,4800,460,4680,460C4560,460,4440,460,4320,460C4200,460,4080,460,3960,460C3840,460,3720,460,3600,460C3480,460,3360,460,3240,460C3120,460,3000,460,2880,460C2760,460,2640,460,2520,460C2400,460,2280,460,2160,460C2040,460,1920,460,1800,460C1680,460,1560,460,1440,460C1320,460,1200,460,1080,460C960,460,840,460,720,460C600,460,480,460,360,460C240,460,120,460,60,460L0,460Z"/></svg>
    <div class="row justify-content-center align-items-center">
      <div class="col-8 col-md-3 text-center">
        <a href="https://blueworks.pl" title="Blueworks.pl - strony WWW, Warszawa">
          <img src="uploads/gfx/logo/blueworks.png" alt="Blueworks.pl - strony www, e-commerce, erp online - Warszawa" class="img-fluid" />
        </a>
        <div class="col-12 text-center">
          <span class="d-block mb-3 mt-3">zapraszamy <span>wkrótce</span></span>
          <h1>strony www</h1> / <h2>sklepy internetowe</h2> / <h3>systemy erp online</h3>
          <a class="d-block mt-3 mb-3" href="mailto:biuro@blueworks.pl">biuro@blueworks.pl</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container-fluid, .row{height:100% !important;}
  .col-12{font-size:1.3em;color:#cfeaf3;}
  .col-12 span span{color: #fff5bd !important;}
  h1,h2,h3{display:inline;font-size:0.8em !important;}
  .col-12 *{transition: 0.3s;color: #cfeaf3;font-weight:400;}
  h2{color: #fff5bd !important;}
  a{color:#ffe44c !important;text-decoration: none !important;}
  a:hover{color:#ff9313 !important;}
  svg{position:absolute;top:-20%;width:100%;z-index:0;}
  .row{z-index:1;position:relative;}
</style>
